import styled from 'styled-components'

export const Wrapper = styled.div`

	top: 1rem;
	z-index: 4;
	right: 1.8rem;
	display: none;
	cursor: pointer;
	transition: left 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	position: absolute;
	@media (max-width: 750px) {
		display: block;}


	${({ sidebar }) =>
		sidebar &&
		`
			right: 1.8rem;
			top: 1rem;
			position: fixed;
	`}
`

export const Bar = styled.div`
	position: relative;
	z-index: 100;
	width: 1.6rem;
	height: 2px;
	margin-bottom: .3rem;
	transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91),
	opacity 500ms,
	box-shadow 250ms,
	background-color 500ms;
	background-color:#f9f5f1;
	@media (max-width: 600px){
		width: 1.6rem;
	}
	

	${({ top, sidebar }) =>
		top &&
		sidebar &&
		`
		transform: translateY(9px) rotate(-135deg);
		-webkit-transform: translateY(9px) rotate(-135deg);
		-ms-transform: translateY(9px) rotate(-135deg);
		background-color:#f9f5f1;
	`}

	${({ mid, sidebar }) =>
		mid &&
		sidebar &&
		`
		transform: scale(0);
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		background-color:#f9f5f1;
	`}

	${({ bottom, sidebar }) =>
		bottom &&
		sidebar &&
		`
		transform: translateY(-5px) rotate(-45deg);
		-webkit-transform: translateY(-5px) rotate(-45deg);
		-ms-transform: translateY(-5px) rotate(-45deg);
		background-color:#f9f5f1;
	`}
`
