import React from 'react'
import { SocialLink, SocialLinkWrapper, SocialContainer, LinkedIn, GitHub, Facebook, Instagram, Twitter} from './styles'
import {FaFacebook, FaInstagram, FaLinkedin, FaGithub, FaTwitter} from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';

export const Social = () => (
	<SocialContainer>

    <SocialLinkWrapper>
      <ScrollAnimation offset="2000" animateOnce={true} delay="2500" animateIn="fadeInLeft">
        <LinkedIn><SocialLink href='https://www.linkedin.com/in/jacobpaulmiller/' aria-label="Access Jacob's LinkedIn Page"><FaLinkedin size={30}/></SocialLink></LinkedIn>
      </ScrollAnimation>

      <ScrollAnimation offset="2000" animateOnce={true} delay="2750" animateIn="fadeInLeft">
        <GitHub><SocialLink href='https://github.com/JakePMiller' aria-label="Access Jacob's Github Page"><FaGithub size={30}/></SocialLink></GitHub>
      </ScrollAnimation>

      <ScrollAnimation offset="2000" animateOnce={true} delay="3000" animateIn="fadeInLeft">
        <Facebook><SocialLink href='https://www.facebook.com/JPM1996' aria-label="Access Jacob's FaceBook Page"><FaFacebook size={30}/></SocialLink></Facebook>
      </ScrollAnimation>

      <ScrollAnimation offset="2000" animateOnce={true} delay="3250" animateIn="fadeInLeft">
        <Instagram><SocialLink href='https://www.instagram.com/haijake/' aria-label="Access Jacob's Instagram Page"><FaInstagram size={30}/></SocialLink></Instagram>
      </ScrollAnimation>

      <ScrollAnimation offset="2000" animateOnce={true} delay="3500" animateIn="fadeInLeft">
        <Twitter><SocialLink href='https://twitter.com/HaiJake' aria-label="Access Jacob's Twitter Page"><FaTwitter size={30}/></SocialLink></Twitter>
      </ScrollAnimation>

    </SocialLinkWrapper>
  </SocialContainer>	
)
