import React from 'react'
import { Header } from '../Header'
import { Wrapper, Content, Name, Button, Description, Context, ContextContainer} from './styles'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ScrollAnimation from 'react-animate-on-scroll';

export const Intro = () => (
	<Wrapper id="intro">
		<Header />
			<Content>
			<ScrollAnimation animateOnce={true} offset="2000" delay="500" animateIn="fadeInUp">
				<Name>Hey there, I'm Jake.</Name>
			</ScrollAnimation>

			<ScrollAnimation animateOnce={true} offset="2000" delay="1250" animateIn="fadeInUp">
				<Description> Developer | Dreamer | Doer</Description>
			</ScrollAnimation>

			<ScrollAnimation animateOnce={true} offset="2000" delay="1750" animateIn="fadeInUp">
				<ContextContainer>
					<Context>
						I'm a Full Stack Software Engineer based in Indianapolis, IN.<br/>
						I write beautiful code.
					</Context>
				</ContextContainer>
			</ScrollAnimation>

				<ScrollAnimation animateOnce={true} offset="2000" delay="2250" animateIn="fadeInUp">
					<Button><AnchorLink href="#contact"><span>Contact Me</span></AnchorLink></Button>
				</ScrollAnimation>

			</Content>

	</Wrapper>	
)
