import React from 'react'
import NavbarLinks from '../NavbarLinks'
import { Wrapper, NavBarLinkWrapper, Name } from './styles'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ScrollAnimation from 'react-animate-on-scroll';

const Navbar = () => (
	<ScrollAnimation offset="2000" animateOnce={true} delay="500" animateIn="fadeInDown">
		<Wrapper>
			<Name><AnchorLink href="#intro">00. Jacob Miller</AnchorLink></Name>	
			<NavBarLinkWrapper>
				<NavbarLinks desktop />
			</NavBarLinkWrapper>
		</Wrapper>
	</ScrollAnimation>
)

export default Navbar
