import styled from 'styled-components'

export const Content = styled.div`
	position: relative;
	z-index:0;
	color:#212121;
	font-family: 'IBM Plex Mono', serif;
	font-size: 14px;
	padding-top: 7.5vh;
	padding-bottom: 7.5vh;
	background-color: white;
`

export const ProjectsContent = styled.div`
color: #212121;

	p {
		font-size: 14px;
		font-weight: normal;
		font-family: 'IBM Plex Mono', Helvetica, sans-serif;
		line-height: 1.3;
		color: #212121;
	}

  * {
	outline: 0;
	box-sizing: border-box;
  }
  
  summary {
	display: block;
	cursor: pointer;
	padding: 10px;
	font-size: 16px;
	transition: .3s;
	border-bottom: 2px solid;
	user-select: none;
  }

  img{
	  text-align: center;
	  margin: auto;
	  width: auto;
	  height: auto;
	  min-height: 300px;
  }
  
  details > div {
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	height: 100%;
	user-select: none;
	padding: 0 20px;
	line-height: 1.5;
	@media (max-width: 550px) {
		flex-direction: column;
	}
  }
  
  details > div > img {
	align-self: flex-start;
	max-width: 100%;
	margin-top: 20px;
  }
  
  details > div > p {
	flex: 1;
  }
  
  details[open] > summary {
	color: #212121;
  }
  
  details[open] > div > p {
	opacity: 0;
	animation-name: showContent;
	animation-duration: 0.6s;
	animation-delay: 0.2s;
	animation-fill-mode: forwards;
	margin: 0;
	padding-left: 20px;
	@media (max-width: 550px) {
		padding-left: 0px;
		padding-top: 5vh;
	}
  }
  
  details[open] > div {
	animation-name: slideDown;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
  }
  
  details[open] > div > img {
	opacity: 0;
	height: 50vh;
	margin: 0;
	animation-name: showImage;
	animation-duration: 0.3s;
	animation-delay: 0.15s;
	animation-fill-mode: forwards;
	@media (max-width: 550px) {
		margin:auto;
	}
  }
  
  @keyframes slideDown {
	from {
	  opacity: 0;
	  height: 0;
	  padding: 0;
	}
  
	to {
	  opacity: 1;
	  height: var(--contentHeight);
	  padding: 20px;
	}
  }
  
  @keyframes showImage {
	from {
	  opacity: 0;
	  clip-path: inset(50% 0 50% 0);
	  transform: scale(0.4);
	}
  
	to {
	  opacity: 1;
	  clip-path: inset(0 0 0 0);
	}
  }
  
  @keyframes showContent {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
`

export const ProjectHeader = styled.div`
h2 {
	position: relative;
	font-size: calc(2vw + 2vh + 1vmin);
	font-family: 'Helvetica';
}

h2 span {
	background-color: white;
    padding-right: 10px;
}

h2:after {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid gray;
    z-index: -1;
}
`