import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Wrapper } from './styles'

const NavbarLinks = ({ desktop }) => (
	<Wrapper desktop={desktop}>
		<AnchorLink href="#about">01. About</AnchorLink>
		<AnchorLink href="#experience">02. Experience</AnchorLink>
		<AnchorLink href="#projects">03. Projects</AnchorLink>
		<AnchorLink href="#contact">04. Contact/Resume</AnchorLink>
	</Wrapper>
)

export default NavbarLinks
