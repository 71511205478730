import React from 'react'
import { ProjectsContent, ProjectHeader, Content } from './styles'
import { Wrapper } from '../Common'
import freud from '../../../static/images/freud.jpg';


export const Projects = () => (
	<Content>
		<Wrapper id="projects">
			<ProjectHeader>
				<h2><span>03. Projects</span></h2>
			</ProjectHeader>
			<ProjectsContent >
				<section>
					<details open>
						<summary>Project 1</summary>
						<div>
						<img src={freud} alt="Freud Pic"/>
						<p>This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. </p>
						</div>
					</details>
					<details>
						<summary>Project 2</summary>
						<div>
						<p>This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. </p>
						</div>
					</details>
					<details>
						<summary>Project 3</summary>
						<div>
						<p>This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. This is a bunch of text describing a project. </p>
						</div>
					</details>
				</section>
			</ProjectsContent>
		</Wrapper>
	</Content>
)
