import React from 'react'
import { AboutContent, AboutHeader, Content } from './styles'
import { Wrapper } from '../Common'
import self from '../../../static/images/me.jpg';

export const About = () => (
	<Content>
		<Wrapper id="about">
			<AboutHeader>
				<h2><span>01. About</span></h2>
			</AboutHeader>
			<AboutContent >
				<img src={self} alt="Jacob Miller"/>
				<p>
					Hi, I'm Jake. I'm a Full Stack Software Engineer 
					based out of Indianapolis, IN (but I currently work remotely).
					<br/><br/>
					I got my undergraduate degree at Indiana University in Computer Science
					with a concentration in Artificial Intelligence. After graduating, I joined 
					Liberty Mutual and have been creating a variety of high-quality applications ever since! 
					<br/><br/>
					In my spare time I like to tinker with robotics, build APIs that make me laugh,
					and contribute to open source projects that interest me.
				</p>
			</AboutContent>
		</Wrapper>
	</Content>
)
