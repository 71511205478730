import React from 'react'
import { Layout, SEO } from '../components/Common'
import { Intro, About, Experience, Projects, Contact, Email, Social } from 'Components'
export default () => (
	<Layout>
		<SEO />
		<Intro />
		<Email/>
		<Social/>
		<About />
		<Experience/>
		<Projects/>
		<Contact />
	</Layout>
)
