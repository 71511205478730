import styled from 'styled-components'
import { media } from "../../Common/Global/media.js";

export const Wrapper = styled.div`

	${({ desktop }) =>
		desktop
			? `
			@media (max-width: 750px) {
				display: none;
		}

			a {
				text-transform: uppercase;
				transition: all 0.2s linear;

					margin-right: 1.5rem;
					&:last-child {
							margin-right: unset;
					}
			}
			a:hover {
				color: #3D97C1;	
				
		}
		`
			: `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
				text-transform: uppercase;
				color: #f9f5f1;

					margin-bottom: 3rem;
					&:last-child {
							margin-bottom: unset;
					}
			}
			a:hover {
				color: #a58861;
		}
	`}
`