import styled from 'styled-components'

export const Content = styled.div`
	position: relative;
	z-index:0;
	color:#212121;
	font-family: 'IBM Plex Mono', serif;
	padding-top: 7.5vh;
	background-color: white;

	a{	
		transition: 300ms;
		color: #3D97C1;
	}

	a:visited{
		text-decoration: none;
		color: #3D97C1;
	}

	.un {
		display: inline-block;
	}
	  
	.un:after {
		content: '';
		width: 0px;
		height: 1px;
		display: block;
		background: black;
		transition: 300ms;
	}
	  
	.un:hover:after {
		width: 100%;
	}
`

export const ExperienceContent = styled.div`
	padding-left: 7.5vw;
    padding-right: 7.5vw;	
	h4{
		margin-block-start: 0;
		margin-block-end: 0.5rem;
		font-family: Helvetica, sans-serif;
		font-size: 18px;
	}
	p{
		margin-block-start: 0;
		margin-block-end: 0rem;
	}
	li{
		padding-bottom: 1rem;
	}
	ul{
		margin-block-start: 0;
		margin-block-end: 0;
		list-style-type: none;

	}

	main {
	min-width: 100px;
	max-width:750px;
	margin: auto;
	}

	div {
	font-size: 14px;
	line-height: 1.3;
	border-top: 3px solid;

	// border-image: linear-gradient(to right, #FCC201, orange, #DC143C);
	// border-image: linear-gradient(to right, #15317E, #0041C2, #6495ED);
	// border-image: linear-gradient(to right, violet, indigo, blue);
	border-image: linear-gradient(to right, #38aecc, #347fb9);


	border-image-slice: 1;
	border-width: 3px;
	margin: 0;
	padding: 40px;
	counter-increment: section;
	position: relative;
	color: #212121;
	font-family: 'IBM Plex Mono', Helvetica, sans-serif;
	font-weight: normal;


	//numbers
	&:before {
		border-width: 2px 2px;
		content: "0"counter(section);
		position: absolute;
		// border-radius: 50%;
		padding: 10px;
		height: 1.25em;
		width: 1.25em;
		background-color: white;
		text-align: center;
		line-height: 1.25em;
		color: #212121;
		font-size: 1.25em;
	}
	}
	//odd number borders
	div:nth-child(odd) {
	border-right: 3px solid;
	border-left: 0px;
	border-bottom: 0;
	padding-left: 0;
	
	&:before {
		border: solid white;
		left: 100%; 
		margin-left: -20px;
	}
	}
	//even number borders
	div:nth-child(even) {
	border-left: 3px solid;
	border-right: 0px;
	border-bottom: 0;
	padding-right: 0;
	
	&:before {
		border: solid white;
		right: 100%; 
		margin-right: -20px;
	}
	}
	//handle first and last
	div:first-child {
	border-top: 0;
	border-bottom: 0;
	border-top-right-radius:0;
	border-top-left-radius:0;
	}
	div:last-child {
	border-bottom-right-radius:0;
	border-bottom-left-radius:0;
	border-bottom: 0;
	}
	`

	export const ExperienceHeader = styled.div`
	h2 {
		font-size: calc(2vw + 2vh + 1vmin);
		position: relative;
		font-family: 'Helvetica';
	}

	h2 span {
		background-color: white;
		padding-right: 10px;
	}

	h2:after {
		content:"";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 0.5em;
		border-top: 1px solid gray;
		z-index: -1;
	}
	`