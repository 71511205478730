import React from 'react'
import { EmailLink, EmailLinkWrapper, EmailContainer} from './styles'
import ScrollAnimation from 'react-animate-on-scroll';

export const Email = () => (
	<EmailContainer>
          <ScrollAnimation offset="2000" animateOnce={true} delay="2500" animateIn="fadeInRight">
            <EmailLinkWrapper>
              <EmailLink target="_top" href={`mailto:jacobmiller7119@live.com`}>millerjakep@gmail.com</EmailLink>
            </EmailLinkWrapper>
          </ScrollAnimation>
    </EmailContainer>	
)
