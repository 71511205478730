import styled from 'styled-components';
import { media } from "../Common/Global/media.js";

export var iconSize=40

export const SocialContainer = styled.div`
  width: 40px;
  position: fixed;
  bottom: 0;
  left: 40px;
  z-index: 2;
  ${media.desktop`left: 25px;`};
  @media (max-width: 750px) {
    display: none;
  }
  div {
    width: 100%;
  }
  margin: 20px auto;
`;

export const SocialLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const SocialLink = styled.a`
  letter-spacing: 0.5px;
  writing-mode: vertical-lr;
  padding: 8px;
  transition-duration: 0.15s;
  color: #212121;
  &:hover{
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
  }
`;

export const LinkedIn = styled.span`
  a:hover{
	  color:#0e76a8;
  }
`;

export const GitHub = styled.span`
  a:hover{
  	color:darkorchid;
  }
`;

export const Facebook = styled.span`
  a:hover{
    color:#3b5998;
  }
`;

export const Twitter = styled.span`
  a:hover{
    color:#1da1f2;
  }
  margin-bottom: 20px;
`;

export const Instagram = styled.span`
  a:hover{
    color:#f46f30;
  }
`;