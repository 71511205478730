import React from 'react'
import NavbarLinks from '../NavbarLinks'
import { Wrapper, LinkedIn, GitHub, Facebook, Instagram, SocialLinks, GlobalStyle, Twitter } from './styles'
import {FaFacebook, FaInstagram, FaLinkedin, FaGithub, FaTwitter} from 'react-icons/fa';

const Sidebar = ({ sidebar, toggle }) => (
	<Wrapper active={sidebar} onClick={() => toggle(!sidebar)}>
		<NavbarLinks/>
		<GlobalStyle active={sidebar}/>
		<SocialLinks>
			
			<LinkedIn><a href="https://www.linkedin.com/in/jacobpaulmiller/" aria-label="Access Jacob's LinkedIn Page"><FaLinkedin size={40}/></a></LinkedIn>
			<GitHub><a href="https://github.com/JakePMiller" aria-label="Access Jacob's GitHub Page"><FaGithub size={40}/></a></GitHub>
			<Facebook><a href="https://www.facebook.com/JPM1996" aria-label="Access Jacob's FaceBook Page"><FaFacebook size={40}/></a></Facebook>
			<Instagram><a href="https://www.instagram.com/haijake/" aria-label="Access Jacob's Instagram Page"><FaInstagram size={40}/></a></Instagram>
			<Twitter><a href="https://twitter.com/HaiJake" aria-label="Access Jacob's Twitter Page"><FaTwitter size={40}/></a></Twitter>

		</SocialLinks>
	</Wrapper>
)

export default Sidebar
