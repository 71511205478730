import styled from 'styled-components'

export const Details = styled.div`
	position: relative;
	color:white;
	font-family: 'IBM Plex Mono', serif;
	a{		
		color: inherit;
	}
`

export const Button = styled.span`
	font-weight: 700;
	display: inline-block;
	position: relative;
	background: transparent;
	color: #f9f5f1;
	font-size: 14px;
	border-color: #f9f5f1;
	border-style: solid;
	border-width: 2px;
	border-radius: 4px;
	padding: 30px 0px;
    text-transform: uppercase;
	font-family: 'IBM Plex Mono', serif;
	z-index: 2;
	text-decoration: none;
	margin-bottom: 20px;
	a{
		text-transform: uppercase;
		padding: 30px 40px;
		color: inherit;
	}
	a:visited{
		text-decoration: none;
		color: inherit;
	}

	&:visited{
		text-decoration: none;
		color: inherit;
	}

	&:hover {
		color: black;
	}
	  
	&:before {
		content: "";
		position: absolute;
		background: #f9f5f1;
		bottom: 0;
		left: 0;
		right: 0;
		top: 100%;
		z-index: -1;
		-webkit-transition: top 0.2s ease-in;
	}
	  
	&:hover:before {
		top: 0;
	}
`

export const Content = styled.div`
	position: relative;
	z-index:2;
	color:white;
	font-family: 'IBM Plex Mono', serif;
	padding-top: 7.5vh;
	padding-bottom: 7.5vh;
	background-color: #212121;
`

export const ContactHeader = styled.div`
h2 {
	font-size: calc(2vw + 2vh + 1vmin);
	position: relative;
	font-family: 'Helvetica';
}

h2 span {
	background-color: #212121;
    padding-right: 10px;
}

h2:after {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid white;
    z-index: -1;
}
`

export const Outer = styled.div`
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		@media (max-width: 550px) {
			flex-direction: column;
		}
}
`

export const Left = styled.div`
	flex: 1;
	text-align: center;
	font-family: 'IBM Plex Mono', serif;
	font-size: 16px;

	a{
		transition-duration: 200ms;
	}
	a:hover{
		color:#212121;
		border-radius: 4px;
		background:#f9f5f1;
		padding:3px;
	}
`

export const Right = styled.div`
	text-align: center;
	flex: 1;
	a{
		transition-duration: 200ms;
	}
	a:hover{
		color:#212121;
	}
	@media (max-width: 550px) {
		padding-top: 5vh;
	}
`

export const Socials = styled.div`
a {
	padding-right: 3px;
	padding-left: 3px;
}	
`

export const LinkedIn = styled.span`
a{
	color:#f9f5f1;
}
a:hover{
	color:#007bb5;
}
`
export const GitHub = styled.span`
a{
	color:#f9f5f1;
}
a:hover{
	color:darkorchid;
}
`
export const Facebook = styled.span`
a{
	color:#f9f5f1;
}
a:hover{
	color:#4e71ba;
}
`

export const Twitter = styled.span`
a{
	color:#f9f5f1;
}
a:hover{
	color:#4e71ba;
}
`

export const Instagram = styled.span`
a{
	color:#f9f5f1;
}
a:hover{
	color:#f46f30;
}
`