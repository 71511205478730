import styled from 'styled-components';
import { media } from "../Common/Global/media.js";

export const EmailContainer = styled.div`
  width: 40px;
  position: fixed;
  bottom: 0;
  right: 40px;
  z-index: 2;
  ${media.desktop`right: 25px;`};

  @media (max-width: 750px) {
    display: none;
  }
  
  div {
    width: 100%;
    margin: 0 auto;
  }
`;

export const EmailLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const EmailLink = styled.a`
  letter-spacing: 2px;
  writing-mode: vertical-rl;
  margin: 20px auto;
  padding: 10px;
  transition-duration: 0.15s;

  color: #212121;
  font-size:15px;
  &:hover {
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
  color: #3D97C1;
}
`;