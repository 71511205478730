import React from 'react'
import { ExperienceContent, ExperienceHeader, Content } from './styles'
import { Wrapper } from '../Common'

export const Experience = () => (
	<Content>
		<Wrapper id="experience">
			<ExperienceHeader>
				<h2><span>02. Experience</span></h2>
			</ExperienceHeader>
			<ExperienceContent >
				<main>
					<div>
						<a href="https://www.libertymutual.com/" rel="noopener" target="_blank"><h4 className="un">Liberty Mutual Insurance</h4></a>
						<p>Software Engineer | April 2020 - Current
							<br/>
							Associate Software Engineer | March 2019 - April 2020
							<br/>
							Technology Associate | June 2018 - March 2019
							<br/>
						</p>
						<br/>
						<ul>
							<li>- Worked within a talented train of full-stack engineers to create a new customer facing portal application.</li>
							<li>- Collaborated with stakeholders and UX experts to create beautiful user experiences utilizing Angular with a Micro-Frontend architecture.</li>
							<li>- Contributed to the design and implementation of blazing-fast, internal APIs using AWS Lambda.</li>
						</ul>
						<br/>
						<p>Technologies: Angular, Java, AWS EC2, AWS Lambda</p>
					</div>

					<div>
						<a href="https://www.iu.edu/" rel="noopener" target="_blank"><h4 className="un">Indiana University Bloomington</h4></a>
						<p>Undergrad. Instructor | August 2017 - December 2017</p>
						<br/>
						<ul>
							<li>- Instructed a class of undergraduate students on iOS application development using Swift and the MVC architectural pattern.</li>
							<li>- Reviewed assignments and held office hours for students to receive additional help.</li>
						</ul>
						<br/>
						<p>Technologies: Swift</p>
					</div>

					<div>
						<a href="https://www.jpmorgan.com/" rel="noopener" target="_blank"><h4 className="un">JPMorgan Chase &amp; Co.</h4></a>
						<p>Software Engineer Intern | June 2017 - August 2017</p>
						<br/>
						<ul>
							<li>- Worked within an Agile team of developers to deliver a rapid prototype of a mobile credit sharing application using MVC architectural patterns and Test-driven development.</li>
							<li>- Pitched the mobile application to senior and executive members of the leadership team for funding consideration.</li>
						</ul>
						<br/>
						<p>Technologies: React, Java, Swift</p>
					</div>

					<div>
						<a href="https://www.iu.edu/" rel="noopener" target="_blank"><h4 className="un">Indiana University Office of the Vice Provost</h4></a>
							<p>Ambassador | March 2016 - August 2016</p>
							<br/>
							<ul>
								<li>- Traveled to South Korea and China to speak with incoming international students about American culture, social norms, and the importance of a liberal arts education.</li>
								<li>- Acquired leadership and public speaking skills by leading large teams of incoming international students in discussions about the importance of diversity and inclusion.</li>
							</ul>
					</div>
				</main>
			</ExperienceContent>
		</Wrapper>
	</Content>
)
