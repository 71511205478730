import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
body {
	overflow: ${({active}) => (active ? 'hidden' : 'unset')};
}
`

export const Wrapper = styled.div`
	position: fixed;
	z-index: 3;
	overflow: auto;
	top: 0px;
	right: -100%;
	width: 0%;
	opacity: 0;
	height: 100vh;
	background-color: #212121;
	transition: all 600ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	-webkit-transition: all 600ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  	-moz-transition: all 600ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  	-o-transition: all 600ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	text-align:center;
	padding: 5vh 0em 0em 0em;
	${({ active }) => active &&
		`
			right: 0px;
			@media (max-width: 750px) {
				width:100%;
				opacity: 1;
				background-color: #212121;
			}
	`}
`

export const SocialLinks = styled.div`
	padding-bottom:20vh;
`

export const LinkedIn = styled.span`
	a{
		color:#f9f5f1;
	}
	a:hover{
		color:#007bb5;
	}
`
export const GitHub = styled.span`
	a{
		color:#f9f5f1;
	}
	a:hover{
		color:darkorchid;
	}
`
export const Facebook = styled.span`
	a{
		color:#f9f5f1;
	}
	a:hover{
		color:#4e71ba;
	}
`

export const Twitter = styled.span`
	a{
		color:#f9f5f1;
	}
	a:hover{
		color:#4e71ba;
	}
`

export const Instagram = styled.span`
	a{
		color:#f9f5f1;
	}
	a:hover{
		color:#f46f30;
	}
`