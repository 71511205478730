import styled from 'styled-components'

export const Wrapper = styled.div`
	background-color:white;
	display: flex;
  	flex-direction: column;
	min-height: 100vh;
`

export const Button = styled.span`
	display: inline-block;
	position: relative;
	background: transparent;
	color: #212121;
	font-size: 14;
	@media screen and (min-width: 320px) and (max-width: 900px){
		font-size: calc(15px + 2 * ((100vw - 320px) / 320));
	}
	@media screen and (min-width: 900px) and (max-width: 1200px){
		font-size: calc(17px + 1 * ((100vw - 900px) / 900));
	}
	@media screen and (min-width: 1201px) {
		font-size: 18px;
	}
	border-color: #212121;
	border-style: solid;
	border-width: 2px;
	border-radius: 4px;
	padding: 20px 0px;
	transition: all .2s linear;
	z-index: 2;
	text-decoration: none;

	a{
		padding: 20px 30px;
		color: inherit;
		font-family: 'IBM Plex Mono', Helvetica, sans-serif;
	}

	a:visited{
		text-decoration: none;
		color: inherit;
	}

	&:visited{
		text-decoration: none;
		color: inherit;
	}

	&:hover {
		color: #e6e5e1;
	}
	  
	&:before {
		content: "";
		position: absolute;
		background: black;
		bottom: 0;
		left: 0;
		right: 0;
		top: 100%;
		z-index: -1;
		-webkit-transition: top 0.2s ease-in;
	}
	  
	&:hover:before {
		top: 0;
	}
`

export const ContextContainer = styled.div`
	width: 65%;
	display: block;
	padding-left: 17.5vw;
`

export const Context = styled.div`
	font-family: 'IBM Plex Mono';
	font-size: 12;
	@media screen and (min-width: 320px) and (max-width: 900px){
		font-size: calc(15px + 2 * ((100vw - 320px) / 320));
	}
	@media screen and (min-width: 900px) and (max-width: 1200px){
		font-size: calc(17px + 1 * ((100vw - 900px) / 900));
	}
	@media screen and (min-width: 1201px) {
		font-size: 18px;
	}
	word-wrap: break-word;
	display: block;
	white-space: normal;
	padding-bottom: 10vh;
`

export const Name = styled.div`
	margin-bottom: .1em;
	color: #191919;
	font-weight: bold;
	line-height: 1;
	font-size: 33px;
	@media screen and (min-width: 320px){
		//33-47px
		font-size: calc(2.0625rem + ((1vw - 3.2px) * 5));
	}
	@media screen and (min-width: 600px){
		//47-63
		font-size: calc(2.9375rem + ((1vw - 6px) * 5.3333));
	}
	@media screen and (min-width: 900px){
		//63-86
		font-size: calc(3.9375rem + ((1vw - 9px) * 7.6667));
	}
	@media screen and (min-width: 1201px) {
		font-size: 86px;
	}
`

export const PhotoWrapper = styled.div`
	width:30%;
	min-width: 300px;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display: block;

	@media (min-width: 768px) and (min-height: 768px) and (max-width: 1366px) and (max-height: 1366px){
		width:60%;
	}
	@media (min-width: 1366px) and (min-height: 1366px){
		width:50%;
	}
`

export const Photo = styled.div`
	img {
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
		margin-bottom: 0rem;
	}
`

export const Description = styled.div`
	padding-bottom: 10vh;
	color: #191919;
	font-family: 'IBM Plex Mono', serif;
	font-size: 14;
	@media screen and (min-width: 320px) and (max-width: 900px){
		font-size: calc(15px + 2 * ((100vw - 320px) / 320));
	}
	@media screen and (min-width: 900px) and (max-width: 1200px){
		font-size: calc(17px + 1 * ((100vw - 900px) / 900));
	}
	@media screen and (min-width: 1201px) {
		font-size: 18px;
	}
`

export const Content = styled.div`
	position: relative;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
	padding-top: 15vh;
    align-items: center;
	padding-bottom: 5vh;
 	color: #212121;
	font-family: 'Helvetica';
	text-align: center;
	white-space: pre;
`