import React, { useState } from 'react'
import Navbar from './Navbar'
import Hamburger from './Hamburger'
import Sidebar from './Sidebar'
import Headroom from 'react-headroom'
import { Wrapper } from './styles'

export const Header = () => {
	const [sidebar, toggle] = useState(false)
	return (
		<Wrapper>
			<Headroom style={{zIndex: 3}}>
				<Navbar />
				<Hamburger sidebar={sidebar} toggle={toggle}/>
				<Sidebar sidebar={sidebar} toggle={toggle} />
			</Headroom>
		</Wrapper>
	)
}
