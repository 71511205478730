import styled from 'styled-components'
import { media } from "../../Common/Global/media.js";

export const Wrapper = styled.div`
	align-items: center;
	background-color:#212121;
	box-shadow: rgba(2, 12, 27, 0.1) 0px 10px 30px -10px;
	height: 50px;
	a {
		color: #f9f5f1;
		font-size: 14px;
	}
`

export const NavBarLinkWrapper = styled.div`
	position: absolute;
	top: 1rem;
	right: 2rem;
`

export const Name = styled.span`
font-family: 'IBM Plex Mono', monospace;
color: #f9f5f1;
font-size: 16px;
font-weight: bold;
text-transform: uppercase;
top: .9rem;
left: 1.8rem;
position: absolute;
a{
	transition: all 0.2s linear;
}
a:hover {
	color: #3D97C1;
}
${media.mobileLandscape`font-size: 16px;`};
`