import styled from 'styled-components'

export const Content = styled.div`
	position: relative;
	z-index:0;
	color:#212121;
	background-color: white;
	overflow:auto;
`

export const AboutContent = styled.div`
	p {
		font-size: 14px;
		font-weight: normal;
		font-family: 'IBM Plex Mono', Helvetica, sans-serif;
		line-height: 1.3;
		color: #212121;
	}
	img{
		max-width:500px;
		width: 50%;
		margin-left: 2rem;
		float: right;
		filter: grayscale(100%);
	}
	@media screen and (orientation:landscape) 
	and (max-device-width: 850px) {
		img{
			max-width: 75%;
		}
	}
	@media (max-width: 850px) {
		text-align: center;
		img{
			padding-top: 5%;
			padding-bottom: 5%;

			margin: auto;
			float: none;
			width:90%;
		}
		p{
			width:100%;
			float: none;
			text-align: left;
		}
	}
`

export const AboutHeader = styled.div`
h2 {
	font-size: calc(2vw + 2vh + 1vmin);
	position: relative;
	font-family: 'Helvetica';
}

h2 span {
	background-color: white;
    padding-right: 10px;
}

h2:after {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid gray;
    z-index: -1;
}
`