import React from 'react'
import { ContactHeader, Content, Button, Details ,Left, Right, Outer, LinkedIn, GitHub, Facebook,Instagram,Twitter, Socials} from './styles'
import { Wrapper } from '../Common'
import {FaFacebook, FaInstagram, FaLinkedin, FaGithub, FaTwitter} from 'react-icons/fa';
import Resume from '../../../static/Jacob_Resume.pdf';

export const Contact = () => (
	<Content>
		<Wrapper id="contact">
			<ContactHeader>
				<h2><span>04. Contact/Resume</span></h2>
				</ContactHeader>
			<Details>
				<Outer>
					<Left>
						<p>Interested in working with me? <br/>Feel free to reach out!</p>
						<p>Phone: <a href="tel:+1(812)-629-6891 ">+1(812)-629-6891</a></p>
						<p>Email: <a href="mailto:millerjakep@gmail.com">millerjakep@gmail.com</a></p>
					</Left>

					<Right>
						<Button><a href={Resume} rel="noopener" target="_blank">Resume</a></Button><br/>
						<Socials>
							<LinkedIn><a href="https://www.linkedin.com/in/jacobpaulmiller/" aria-label="Access Jacob's LinkedIn Page"><FaLinkedin size={40}/></a></LinkedIn>
							<GitHub><a href="https://github.com/JakePMiller" aria-label="Access Jacob's GitHub Page"><FaGithub size={40}/></a></GitHub>
							<Facebook><a href="https://www.facebook.com/JPM1996" aria-label="Access Jacob's FaceBook Page"><FaFacebook size={40}/></a></Facebook>
							<Instagram><a href="https://www.instagram.com/haijake/" aria-label="Access Jacob's Instagram Page"><FaInstagram size={40}/></a></Instagram>
							<Twitter><a href="https://twitter.com/HaiJake" aria-label="Access Jacob's Twitter Page"><FaTwitter size={40}/></a></Twitter>
						</Socials>
					</Right>			
				</Outer>
			</Details>
		</Wrapper>
	</Content>
)
